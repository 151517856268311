<template>
  <div class="callsTable" :class="loggedIn ? '' : 'px-12'">
    <h1 class="text-center main-color">
      <v-icon large color="#7297ff">mdi-account-tie-voice-outline</v-icon>
      {{ $t("Nidaa") }} <span v-if="className">( {{ className }} ) </span>
    </h1>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{ $t("Student Name") }}</th>
            <th class="text-left">{{ $t("Call Time") }}</th>
            <th class="text-left">{{ $t("Retries") }}</th>
            <th class="text-left">{{ $t("Checkout") }}</th>
          </tr>
        </thead>
        <tbody v-if="items.length > 0">
          <tr v-for="item in items" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.time }}</td>
            <td>{{ item.retries }}</td>
            <td>
              <v-icon
                class="main-color"
                title="Checkout"
                @click="checkout(item.code)"
                large
                >logout</v-icon
              >
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4">
              <p class="text-center">{{ $t("There is no data available") }}</p>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "callsTable",
  props: ["uuid"],
  data() {
    return {
      loggedIn: localStorage.getItem("login") == "true" ? true : false,
      items: [],
      className: "",
      classId: "",
    };
  },
  methods: {
    getAllCalls() {
      this.$http
        .get(this.getApiUrl + "/nedaa/getCallList/" + this.uuid)
        .then((response) => {
          this.items = response.data.data.call_list;
          this.className = response.data.data.class;
          this.classId = response.data.data.class_id;
        });
    },
    checkout(uuid) {
      if (
        confirm(this.$i18n.t("Are you sure you want to checkout this student?"))
      ) {
        this.$http
          .get(this.getApiUrl + "/nedaa/checkOutStudent/" + uuid)
          .then((response) => {
            if (response.data.status.error == false) {
              this.getAllCalls();
            }
          });
      }
    },
  },
  mounted() {
    this.getAllCalls();
    let _this = this;
    if (process.env.VUE_APP_SOCKET_URL != undefined) {
      this.$socket.channel("StudentCallList").listen("StudentCallList", (e) => {
        if (parseInt(_this.classId) == parseInt(e.class_id)) {
          _this.getAllCalls();
        }
        console.log(e);
      });
    }
  },
};
</script>

<style></style>
