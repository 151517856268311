<template>
  <div v-if="loaded">
    <v-row>
      <v-col class="my-4" v-if="!verified">
        <div class="card-container d-flex justify-center align-center">
          <v-card width="400px" class="py-8 px-4">
            <div class="logo text-center">
              <img :src="logo" alt="school-logo" />
            </div>
            <h2 class="main-color text-center">
              {{ $t("Verification Code") }}
            </h2>

            <v-form v-model="valid" ref="form">
              <label for="varificationCode">{{
                $t("Verification Code")
              }}</label>
              <v-text-field
                name="varificationCode"
                class="mx-auto mx-4"
                v-model="verficationCode"
                :rules="[validationRules.required]"
                min="1"
                solo
              ></v-text-field>

              <p class="error--text text-center">{{ validationMsg }}</p>
              <v-card-actions class="text-center">
                <v-btn class="main-btn mx-auto" @click.prevent="submitCode">{{
                  $t("Verify")
                }}</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </div>
      </v-col>

      <v-col v-else :class="loggedIn ? 'mt-8' : 'mt-16'">
        <calls-table :uuid="uuid"></calls-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { validationMixin } from "@/mixins/validationMixin";
import callsTable from "@/components/nedaa/callsTable.vue";

export default {
  name: "linkVerification",
  mixins: [validationMixin],
  components: { callsTable },
  data() {
    return {
      loggedIn: localStorage.getItem("login") == "true" ? true : false,

      valid: false,
      verficationCode: "",
      correctCode: "",
      uuid: "",
      verified: false,
      loaded: false,
      validationMsg: "",
      logo: "",
    };
  },
  methods: {
    getVerificationCode() {
      this.$http
        .get(this.getApiUrl + "/nedaa/getPageCode/" + this.uuid)
        .then((response) => {
          if (response.data.status.error == false) {
            this.loaded = true;
            this.correctCode = response.data.data.code;
            this.logo = response.data.data.logo;
            // localStorage.setItem("verificationCode", response.data.data);
            let userCode = localStorage.getItem("verificationCode")
              ? localStorage.getItem("verificationCode")
              : "";

            if (this.correctCode == userCode) {
              this.verified = true;
            }
          }
        });
    },

    submitCode() {
      this.validationMsg = "";
      if (this.valid) {
        if (this.verficationCode == this.correctCode) {
          localStorage.setItem("verificationCode", this.verficationCode);
          this.verified = true;
        } else {
          this.validationMsg = this.$i18n.t("Invalid Code");
        }
      } else {
        this.validate();
      }
    },
  },
  mounted() {
    this.uuid = this.$route.params.id;

    this.getVerificationCode();
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  height: 90vh;

  .logo {
    img {
      width: 150px;
    }
  }
}
</style>
